// Import types
import MMDDYY from '../types/MMDDYY';

/**
 * Function for comparing dates.
 * @author Benedikt Arnarsson
 * @param date1 first date for comparison
 * @param date2 second date for comparison
 * @returns true if date1 is before date2, otherwise false
 */
const isBeforeMMDDYY = (date1: MMDDYY, date2: MMDDYY): boolean => {
  if (date1.year !== date2.year) {
    // Year is not the same
    return date1.year < date2.year;
  }

  // Year is the same. Also check the month:
  if (date1.month !== date2.month) {
    // Month is not the same
    return date1.month < date2.month;
  }

  // Month is the same. Also check the day
  return date1.day < date2.day;
};

export default isBeforeMMDDYY;
